<template>
  <lazy-component
    ref="videoRoot"
    class="video-lazy-wrapper"
    :class="[ratio ? `ratio ratio-${ratio}` : '', { 'full-height': fullHeight }]"
    @show="onShow"
  >
    <div
      ref="videoWrapper"
      class="video-wrapper"
      :class="{ initialized: !!player, 'non-interactive': !interactive }"
      @click="play"
      @touchstart="play"
    >
      <video
        :id="'video-container-' + id"
        ref="videoPlayer"
        class="video-js"
        :class="{ 'no-preview': !previewSprites.length }"
        preload="auto"
        :poster="restricted ? poster : null"
        crossorigin="anonymous"
        playsinline
      >
        <source v-if="!restricted" :src="source" :type="sourceType" />
        <track
          v-for="subtitle in subtitles"
          :key="subtitle.lang"
          kind="subtitles"
          :src="subtitle.src"
          :srclang="subtitle.lang"
          :label="subtitle.label"
          :default="lang === subtitle.lang ? 'true' : false"
        />
      </video>

      <video-activity-graph v-if="showVideoActivity" ref="activityGraph" :video-id="id" :size="graphSize" />
    </div>
  </lazy-component>
</template>

<script>
import { mapGetters } from "vuex";
import { getMediaUrl, getVideoTimelinePreview } from "@/utils/mediaHelper";
import videoService from "@/api/services/videoService";
import { amplitudeTrack, amplitudeVideoTrack } from "@/utils/amplitude";
import browserHelper from "@/utils/browserHelper";
import { ENV_PRODUCTION } from "@/utils/variables";
import videojs from "video.js";
import "videojs-quality-selector-hls";
import "videojs-sprite-thumbnails";
import "videojs-mux";
import "videojs-mobile-ui";
import "videojs-hotkeys";
import { insertScript } from "@/utils/domHelper";
import mobileTimelinePreview from "@/utils/playerHelpers/mobileTimelinePreview";
import seekButtons from "@/utils/playerHelpers/seekButtons";
import VideoActivityGraph from "@/components/video-activity-graph/index.vue";
import { addGoal, setViewPortion } from "@/utils/recommendor";
import pipButton from "@/utils/playerHelpers/pipButton";

export default {
  // TODO: make aspect-ratio 16x9 by default, remove legacy styles related to player aspect ratio from other components
  name: "player",
  components: { VideoActivityGraph },
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    interactive: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    source: {
      type: String,
      required: true,
    },
    sourceType: {
      type: String,
      required: false,
      default: "application/x-mpegURL",
    },
    poster: {
      type: String,
      default: null,
    },
    duration: {
      type: Number,
      required: false,
      default: null,
    },
    subtitlesEn: {
      type: String,
      required: false,
      default: null,
    },
    subtitlesDe: {
      type: String,
      required: false,
      default: null,
    },
    subtitlesFr: {
      type: String,
      required: false,
      default: null,
    },
    showVideoActivity: {
      type: Boolean,
      required: false,
      default: false,
    },
    durationLimit: {
      type: [Number, String],
      required: false,
      default: null,
    },
    trackPlayback: {
      type: Boolean,
      required: false,
      default: false,
    },
    restricted: {
      type: Boolean,
      required: false,
      default: false,
    },
    ratio: {
      type: String,
      default: "",
      validator(value) {
        return ["", "16x9", "4x3"].includes(value);
      },
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    vrProjection: {
      type: String,
      required: false,
      default: null,
    },
    preloadThumbnails: {
      type: Boolean,
      required: false,
      default: false,
    },
    recommendationSourceId: {
      type: String,
      required: false,
      default: null,
    },
    recommendationItemId: {
      type: String,
      required: false,
      default: null,
    },
    isTheaterMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["onLimitedVideoAction", "onShow", "onUserActive", "onUserInactive", "onFirstPlay"],
  data() {
    return {
      player: null,
      currentTextTrackIndex: null,
      started: false,
      timestampsWatched: {},
      log: {
        timerStart: 0,
        currentTime: 0,
        previousTime: 0,
        playbackSent: false,
      },
      graphSize: {
        w: 300,
        h: 40,
      },
    };
  },
  computed: {
    ...mapGetters("user", { profile: "getProfile" }),
    ...mapGetters("video", { activeVideo: "getActiveVideo" }),
    ...mapGetters("player", { quality: "getQuality", volume: "getVolume", isGlobalTheaterMode: "isTheaterMode" }),
    ...mapGetters("feature", ["hasFrench"]),
    lang() {
      return this.$root.$i18n.locale;
    },
    previewSprites() {
      if (!this.duration) {
        return [];
      }

      const count = Math.ceil(this.duration / 5);
      const sprites = [];

      for (let i = 1; i <= count; i++) {
        sprites.push(getVideoTimelinePreview(`${this.id}/img${i}.png`));
      }

      return sprites;
    },
    subtitles() {
      const subtitles = [];

      if (this.subtitlesEn) {
        subtitles.push({ label: "English", lang: "en", src: this.getSubtitles(this.subtitlesEn) });
      }

      if (this.hasFrench && this.subtitlesFr) {
        subtitles.push({ label: "French", lang: "fr", src: this.getSubtitles(this.subtitlesFr) });
      }

      if (this.subtitlesDe) {
        subtitles.push({ label: "Deutsch", lang: "de", src: this.getSubtitles(this.subtitlesDe) });
      }

      return subtitles;
    },
  },
  watch: {
    activeVideo(newVal, oldVal) {
      if (oldVal === this.id && this.player) {
        this.player.pause();
        this.sendPlayback();
        this.sendPortionWatched();
      }
    },
    autoplay(value) {
      if (value) {
        if (this.restricted) {
          this.$store.dispatch("user/showSubscriptionPaywall");
        } else {
          if (this.player && this.player.paused()) {
            setTimeout(() => {
              this.player.play().catch(() => {});
            }, 100);
          }
        }
      }
    },
    currentTextTrackIndex(val) {
      if (!window.cast) {
        return;
      }

      const mediaSession = window.cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession();

      if (!mediaSession) {
        return;
      }

      const tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(val === null ? [] : [val]);
      mediaSession.editTracksInfo(tracksInfoRequest, null, null);
    },
  },
  methods: {
    async play() {
      if (this.restricted) {
        return;
      }

      if (this.id !== null && !this.started) {
        amplitudeVideoTrack(`Video play click`, this.id);
      }

      if (this.isGlobalTheaterMode && !this.isTheaterMode && this.interactive) {
        await this.$store.dispatch("player/setTheaterMode", { videoId: null });
        await this.$store.dispatch("video/setActiveVideo", { videoId: this.id });
      }

      this.$refs.videoPlayer.focus({ preventScroll: true });
    },
    getQualityFromButton(el) {
      const numberPattern = /\d+/g;
      const qualityText = el.querySelector(".vjs-menu-item-text").innerText;
      return qualityText === "Auto" ? qualityText.toLowerCase() : parseInt(qualityText.match(numberPattern).join(""));
    },
    load() {
      if (this.player || !this.$refs.videoPlayer) {
        return;
      }

      const seekStep = 10;

      const options = {
        controls: true,
        errorDisplay: false,
        disablePictureInPicture: true,
        techOrder: ["html5"],
        plugins: {
          hotkeys: {
            volumeStep: 0.1,
            seekStep: seekStep,
            enableModifiersForNumbers: false,
            enableVolumeScroll: false,
          },
          mobileUi: {
            fullscreen: {
              enterOnRotate: true,
              lockOnRotate: !videojs.browser.IS_IOS,
              lockToLandscapeOnEnter: !videojs.browser.IS_IOS,
            },
          },
        },
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            overrideNative: true,
            handlePartialData: true,
          },
        },
        controlBar: {
          pictureInPictureToggle: false,
        },
        poster: this.poster,
      };

      if (videojs.getPlugin("chromecast") !== undefined && window.cast) {
        options.techOrder.unshift("chromecast");
        options.chromecast = {
          modifyLoadRequestFn: (loadRequest) => {
            const tracks = [];

            for (let i = 0; i < this.subtitles.length; i++) {
              const track = new window.chrome.cast.media.Track(i, window.chrome.cast.media.TrackType.TEXT);
              track.trackContentId = this.subtitles[i].src;
              track.trackContentType = "text/vtt";
              track.subtype = window.chrome.cast.media.TextTrackType.CAPTIONS;
              track.name = this.subtitles[i].label;
              track.language = this.subtitles[i].lang;
              tracks.push(track);
            }

            loadRequest.activeTrackIds = this.currentTextTrackIndex !== null ? [this.currentTextTrackIndex] : [];
            loadRequest.media.tracks = tracks;

            return loadRequest;
          },
        };
        options.plugins.chromecast = {};
      }

      if (process.env.VUE_APP_MUX_KEY && this.interactive) {
        let cdn_experiment = "default";

        if (this.profile?.features?.cdn_experiment) {
          cdn_experiment = this.profile?.features?.cdn_experiment.version === "experiment" ? "cdn77" : "cloudflare";
        }

        options.plugins.mux = {
          debug: false,
          minimumRebufferDuration: 250,
          data: {
            env_key: process.env.VUE_APP_MUX_KEY,
            viewer_user_id: this.profile?.id || "",
            video_title: this.id,
            player_init_time: Date.now(),
            experiment_name: cdn_experiment,
            video_cdn: cdn_experiment,
          },
        };
      }

      this.player = videojs(this.$refs.videoPlayer, options, () => {
        this.player.volume(this.volume);
        this.$nextTick(() => {
          const touchOverlay = this.$refs.videoWrapper.querySelector(".vjs-touch-overlay");

          if (touchOverlay) {
            const observer = new MutationObserver(() => {
              if (touchOverlay.classList.length === 1 && !this.player.paused()) {
                this.player.controlBar.addClass("hide");
              } else {
                this.player.controlBar.removeClass("hide");
              }
            });

            observer.observe(touchOverlay, {
              attributes: true,
              attributeFilter: ["class"],
              childList: false,
              characterData: false,
            });
          }

          seekButtons(this.player, seekStep);

          if (this.isTheaterMode) {
            pipButton(this.player);
          }

          if (this.showVideoActivity) {
            const progressBar = this.$refs.videoWrapper.querySelector(".vjs-progress-control");
            progressBar.append(this.$refs.activityGraph.$el);
          }
        });
      });

      if (this.vrProjection) {
        this.player.vr({
          projection: this.vrProjection,
        });
      }

      if (this.player.airPlay !== undefined) {
        this.player.airPlay();
      }

      if (this.player.chromecast !== undefined) {
        this.player.chromecast();
      }

      if (this.previewSprites.length) {
        if (this.preloadThumbnails) {
          this.previewSprites.forEach((url) => {
            const tempImage = new Image();
            tempImage.src = url;
            tempImage.decode().catch(() => {});
          });
        }

        if (browserHelper.isMobileDevice) {
          mobileTimelinePreview(this.player, this.previewSprites);
        } else {
          this.player.spriteThumbnails({
            urlArray: this.previewSprites,
            columns: 1,
            rows: 1,
            width: 160,
            height: 90,
            interval: 5,
            duration: 5,
            downlink: 0,
          });
        }
      }

      this.player.one("play", async () => {
        this.started = true;
        this.$emit("onFirstPlay");
        amplitudeVideoTrack("video starts playing", this.id);
      });

      this.player.on("timeupdate", () => {
        const curTime = +this.player.currentTime().toFixed(0);

        this.timestampsWatched[curTime] = 1;
      });

      this.player.on("play", async () => {
        this.$store.dispatch("video/setActiveVideo", {
          videoId: this.id,
        });
      });

      this.player.on("texttrackchange", () => {
        const textTracks = this.player.textTracks();
        const subtitleTracks = [];

        for (let i = 0; i < textTracks.length; i++) {
          if (textTracks[i].kind !== "subtitles") {
            continue;
          }

          subtitleTracks.push(textTracks[i]);
        }

        for (let i = 0; i < subtitleTracks.length; i++) {
          if (subtitleTracks[i].mode === "showing") {
            this.currentTextTrackIndex = i;
            return;
          }
        }

        this.currentTextTrackIndex = null;
      });

      if (!videojs.browser.IS_IOS) {
        const qualitySelector = this.player.qualitySelectorHls({ displayCurrentQuality: true });

        this.player.one("loadedmetadata", () => {
          let currentQualityButton;
          const quality = this.quality !== "auto" ? parseInt(this.quality) : "auto";
          this.$refs.videoWrapper.querySelectorAll(".vjs-quality-selector .vjs-menu-item").forEach((el) => {
            if (quality === this.getQualityFromButton(el)) {
              currentQualityButton = el;
            }

            el.addEventListener("click", (ev) => {
              const quality = this.getQualityFromButton(ev.currentTarget);
              this.$store.dispatch("player/setQuality", { quality });
            });
          });

          if (!currentQualityButton) {
            return;
          }

          this.$refs.videoWrapper.querySelector(".vjs-quality-selector .vjs-selected").classList.remove("vjs-selected");
          currentQualityButton.classList.toggle("vjs-selected", true);
          qualitySelector.setQuality(quality);
        });
      }

      this.player.on("timeupdate", () => {
        if (this.durationLimit !== null && this.durationLimit > 0) {
          if (this.player.currentTime() >= this.durationLimit) {
            this.player.currentTime(this.durationLimit - 1);
            this.player.pause();
            this.$emit("onLimitedVideoAction");
            amplitudeTrack("Limited video stopped");
          }
        }

        this.log.previousTime = this.log.currentTime;
        this.log.currentTime = this.player.currentTime();
      });

      this.player.on("play", () => {
        this.log.playbackSent = false;
        this.log.timerStart = this.player.currentTime();
        window.addEventListener("beforeunload", this.sendPlayback);
        window.addEventListener("beforeunload", this.sendPortionWatched);
      });

      this.player.on("volumechange", () => {
        const volume = this.player.muted() ? 0 : parseFloat(this.player.volume());
        this.$store.dispatch("player/setVolume", { volume });
      });

      this.player.on("seeking", () => {
        if (!this.log.playbackSent) {
          this.sendPlayback();
        }
      });

      this.player.on("ended", () => {
        this.sendPlayback();
      });

      this.player.on("useractive", () => {
        this.$emit("onUserActive");
      });

      this.player.on("userinactive", () => {
        this.$emit("onUserInactive");
      });

      if (this.autoplay && this.player.paused()) {
        setTimeout(() => {
          this.player.play().catch(() => {});
        }, 100);
      }
    },
    sendPlayback() {
      if (!this.trackPlayback) {
        return;
      }

      if (this.log.playbackSent) {
        return;
      }

      const duration = this.log.previousTime - this.log.timerStart;

      if (Math.floor(duration) <= 0) {
        return;
      }

      videoService
        .playback({
          videoId: this.id,
          duration: duration,
          startDate: Math.floor(Date.now() / 1000),
          timelineStart: this.log.timerStart,
        })
        .catch(() => {})
        .finally(() => {
          this.log.playbackSent = true;
        });
    },
    sendPortionWatched() {
      if (!this.duration || !this.recommendationItemId) {
        return;
      }

      const durationWatched = Object.keys(this.timestampsWatched).length;

      if (!durationWatched) {
        return;
      }

      const portion = Math.min(1, Object.keys(this.timestampsWatched).length / this.duration);
      this.timestampsWatched = {};

      if (portion >= 0.05) {
        setViewPortion(this.recommendationItemId, portion, this.recommendationSourceId);
      }

      if (portion >= 0.7) {
        addGoal(this.recommendationItemId, this.recommendationSourceId);
      }

      this.$store.dispatch("video/setLastPortion", portion);
    },
    async onShow() {
      this.$emit("onShow");

      if (this.restricted) {
        return;
      }

      videojs.log.warn = function () {};

      if (!window.videojs) {
        window.videojs = videojs;
      }

      await insertScript(getMediaUrl("assets/js", "silvermine-videojs-airplay.min.js"));
      await insertScript(getMediaUrl("assets/js", "silvermine-videojs-chromecast.min.js"));
      await insertScript("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1");
      await insertScript(getMediaUrl("assets/js", "videojs-vr.js"));
      await this.$nextTick();
      this.load();

      window.addEventListener("resize", this.setActivityGraphSize);
      this.setActivityGraphSize();
    },
    getSubtitles(name) {
      if (name === "") {
        return "";
      }

      name = name.split(".").slice(0, -1).join(".") + ".vtt";
      // todo: workaround for subtitles on ios due to cors problem
      const subtitleHost = process.env.VUE_APP_ENV === ENV_PRODUCTION ? location.origin : process.env.VUE_APP_MEDIA_CONTENT_HOST;
      return `${subtitleHost}/content/subtitles/${name}`;
    },

    setFullHeight() {
      const root = this.$refs.videoRoot.$el;

      if (root) {
        root.style.setProperty("--app-height", `${window.innerHeight}px`);
      }
    },

    setActivityGraphSize() {
      if (!this.$refs.videoWrapper) {
        return;
      }

      this.graphSize.w = this.$refs.videoWrapper.offsetWidth;
      this.graphSize.h = this.graphSize.w > 576 ? 40 : 25;
    },
  },
  mounted() {
    if (this.fullHeight || browserHelper.isMobileDevice) {
      this.setFullHeight();
      window.addEventListener("resize", this.setFullHeight);

      if (screen?.orientation) {
        screen.orientation.addEventListener("change", this.setFullHeight);
      }
    }
  },
  beforeUnmount() {
    if (this.player && this.id !== this.activeVideo) {
      this.player.dispose();
    }

    window.removeEventListener("beforeunload", this.sendPlayback);
    window.removeEventListener("beforeunload", this.sendPortionWatched);
    window.removeEventListener("resize", this.setFullHeight);
    window.removeEventListener("resize", this.setActivityGraphSize);

    if (screen?.orientation) {
      screen.orientation.removeEventListener("change", this.setFullHeight);
    }

    const isVideoChanged = this.id !== this.activeVideo && this.activeVideo && this.isTheaterMode === this.isGlobalTheaterMode;
    const isClosedTheater = !this.activeVideo && this.isTheaterMode !== this.isGlobalTheaterMode;
    const isClosedInline = !this.isTheaterMode && !this.isGlobalTheaterMode && this.id === this.activeVideo;
    const isClosed = isClosedInline || isClosedTheater;

    if (!isVideoChanged && !isClosed) {
      return;
    }

    this.sendPlayback();
    this.sendPortionWatched();

    if (isClosed) {
      amplitudeVideoTrack("Video Player close", {
        Captions: this.currentTextTrackIndex !== null ? this.subtitles[this.currentTextTrackIndex].lang : null,
      });
      this.$store.dispatch("video/setActiveVideo", {
        videoId: null,
      });
    }
  },
};
</script>

<!-- todo: move to public once fully switched -->
<style>
@import "https://cdn-cf.ersties.com/assets/css/videojs-vr.min.css";
</style>

<style lang="scss">
@import "video.js/dist/video-js.css";
@import "videojs-mobile-ui/dist/videojs-mobile-ui.css";
@import "@/assets/silvermine/silvermine-videojs-chromecast.css";
@import "@/assets/silvermine/silvermine-videojs-airplay.css";
@import "./index";
</style>
