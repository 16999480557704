import userService from "@/api/services/userService";
import { removeAccessToken } from "@/utils/storage";
import { getTld } from "@/utils/urlHelper";
import { POPUP_BUY_SUBSCRIPTION } from "@/utils/variables";
import router from "@/router/router";
import { changeLanguage } from "@/i18n";

// TODO: move things related to authentication to a separate "auth" store

export default () => {
  return {
    namespaced: true,
    state: {
      isLoggedIn: false,
      profile: null,
      busy: false,
    },
    getters: {
      isLoggedIn(state) {
        return state.isLoggedIn;
      },
      getProfile(state) {
        return state.profile;
      },
      isPremium(state) {
        const now = new Date().getTime() / 1000;
        return state.profile?.premium_until && state.profile?.premium_until > now;
      },
      isOrganic(state) {
        return state.profile?.registration_campaign === null;
      },
      canSeeFeedbackButton(state) {
        return state.profile?.features?.feedback?.is_active || false;
      },
      isShowPlaydateDescriptionBlock(state) {
        return state.profile?.settings?.playdate_block || false;
      },
      showUpcomingPlaydates(state) {
        return state.profile?.settings?.upcoming_playdate_block || false;
      },
      isBusy(state) {
        return state.busy;
      },
      hasSignupToSaleFeature(state) {
        const feature = state.profile?.features?.signup_to_sale;
        return feature?.is_active && feature?.version?.indexOf("experiment") !== -1;
      },
      hasMrSkinDiscountFeature(state) {
        if (!state.isLoggedIn) {
          return false;
        }

        const feature = state.profile?.features?.mrskin_discount;
        return feature?.is_active;
      },
      hasAugustDiscountFeature(state) {
        if (!state.isLoggedIn) {
          return false;
        }

        const feature = state.profile?.features?.august_discount;
        return feature && feature?.is_active;
      },
      hasLadiesDiscountFeature(state) {
        if (!state.isLoggedIn) {
          return false;
        }

        const feature = state.profile?.features?.ladies_discount;
        return feature?.is_active;
      },
      hasCustomerSupportDiscountFeature(state) {
        if (!state.isLoggedIn) {
          return false;
        }

        const feature = state.profile?.features?.customer_support_discount;
        return feature?.is_active;
      },

      hasWDSDiscountFeature(state) {
        if (!state.isLoggedIn) {
          return false;
        }

        const feature = state.profile?.features?.wds_discount;
        return feature?.is_active;
      },
    },
    mutations: {
      SET_IS_LOGGED_IN(state, status) {
        state.isLoggedIn = status;
      },
      SET_PROFILE(state, profile) {
        state.profile = profile;
      },
      SET_SETTINGS(state, setting) {
        state.profile.settings = { ...state.profile.settings, ...setting };
      },
      SET_COINS(state, coins) {
        state.profile = { ...state.profile, ...{ coins } };
      },
      SET_BUSY(state, busy) {
        state.busy = busy;
      },
    },
    actions: {
      updateSettings({ commit }, setting) {
        commit("SET_SETTINGS", setting);
      },

      chargeCoins({ commit, state }, coins) {
        const new_coins = state.profile.coins - coins;
        commit("SET_COINS", new_coins);
      },

      async updateEmail({ commit, state }, { email, password = null }) {
        await userService.changeEmail(email, password);
        const newProfile = { ...state.profile, email };
        commit("SET_PROFILE", newProfile);
      },

      async updateUsername({ commit, state }, { username, chatname, password }) {
        await userService.changeUserName(username, chatname, password);
        const newProfile = { ...state.profile, username, chatname };
        commit("SET_PROFILE", newProfile);
      },

      async updateNotifications({ commit, state }, { playdate_notification, camera_mail_notification }) {
        await userService.changeNotifications(playdate_notification, camera_mail_notification);

        const newProfile = { ...state.profile, playdate_notification, camera_mail_notification };
        commit("SET_PROFILE", newProfile);
      },

      async updateUserData({ commit, state }, { first_name, last_name, street, house, zip, city, country }) {
        await userService.changeUserData(first_name, last_name, street, house, zip, city, country);

        const newProfile = { ...state.profile, first_name, last_name, street, house, zip, city, country };
        commit("SET_PROFILE", newProfile);
      },

      async getProfile({ commit, state }) {
        if (state.busy) {
          return;
        }

        commit("SET_BUSY", true);
        let userProfile = null;

        try {
          userProfile = await userService.getProfile();

          changeLanguage(userProfile.language);
          commit("SET_PROFILE", userProfile);
          commit("SET_IS_LOGGED_IN", true);
        } catch (e) {
          removeAccessToken();
          commit("SET_PROFILE", null);
          commit("SET_IS_LOGGED_IN", false);

          if (e.status?.status !== 401) {
            throw e;
          }
        } finally {
          commit("SET_BUSY", false);
        }

        return userProfile;
      },

      async logout({ commit, dispatch }, redirect) {
        await commit("SET_IS_LOGGED_IN", false);
        await removeAccessToken();

        dispatch("popup/closeAll", {}, { root: true });

        if (redirect) {
          const url = typeof redirect === "string" ? { path: redirect, query: { logout: true } } : redirect;
          await router.push(url);
        }

        commit("SET_PROFILE", null);

        if (redirect) {
          try {
            await fetch(location.origin + "/dopages/logout.php", {
              credentials: "include",
              cache: "no-store",
              mode: "cors",
              redirect: "error",
              headers: { "X-Source-Tld": getTld() },
            });
          } catch (e) {
            /* empty */
          }
        }
      },
      showSubscriptionPaywall({ dispatch, getters }, payload = {}) {
        if (
          getters.hasSignupToSaleFeature ||
          getters.hasMrSkinDiscountFeature ||
          getters.hasLadiesDiscountFeature ||
          getters.hasCustomerSupportDiscountFeature ||
          getters.hasAugustDiscountFeature ||
          getters.hasWDSDiscountFeature
        ) {
          router.push("/paywall");
        } else {
          dispatch("popup/show", { id: POPUP_BUY_SUBSCRIPTION, data: payload }, { root: true });
        }
      },
    },
  };
};
