import i18n from "../i18n";
import { getTitle, getTitlePrefix } from "@/utils/title";
import {
  IMLIVE_SORT_FREE_CHAT,
  LAYOUT_BLANK,
  LAYOUT_FREETOUR,
  LAYOUT_MAIN,
  LAYOUT_V2,
  LAYOUT_V2_BLOG,
  V2_HEADER_THEME_NUDE,
  V2_HEADER_THEME_PEACH,
} from "@/utils/variables";
import store from "@/store";

export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: 'home' */ "@/pages/home"),
    meta: {
      title: () => {
        const year = new Date().getFullYear();
        return `${getTitlePrefix()} \u00A9 ${year} - ${i18n.global.t(
          "german porn | amateur porn | amateur sex videos | german amateurs | private amateurs",
        )}`;
      },
      layout: LAYOUT_V2,
    },
  },
  {
    path: "/forgot_password",
    component: () => import(/* webpackChunkName: 'forgot-password' */ "@/pages/forgot-password"),
    meta: {
      title: () => getTitle("Forgot Password"),
      layout: LAYOUT_V2,
    },
  },
  {
    path: "/reset_password",
    component: () => import(/* webpackChunkName: 'reset-password' */ "@/pages/reset-password"),
    meta: {
      title: () => getTitle("Password recovery"),
      layout: LAYOUT_V2,
    },
  },
  {
    path: "/join",
    redirect: "/",
  },
  {
    path: "/videos",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      props.order = route.query.order ?? null;
      props.category = route.query.category ?? null;
      props.ids = route.query.ids ? route.query.ids.split(",").map((id) => parseInt(id)) : [];
      props.query = route.query.query ?? null;
      return props;
    },
    component: () => import(/* webpackChunkName: 'video-group' */ "@/pages/videos"),
    meta: {
      title: () => getTitle("Videos"),
      requiresAuth: true,
    },
  },
  {
    path: "/feeds",
    component: () => import(/* webpackChunkName: 'video-group' */ "@/pages/feeds"),
    meta: {
      title: () => getTitle("Feeds"),
      requiresAuth: true,
    },
  },
  {
    path: "/recent_updates",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'updates' */ "@/pages/updates"),
    meta: {
      title: () => getTitle("Recent updates"),
      requiresAuth: true,
    },
  },
  {
    path: "/update/:id",
    props: (route) => {
      return {
        id: route.params.id,
      };
    },
    component: () => import(/* webpackChunkName: 'update' */ "@/pages/update"),
    meta: {
      title: () => getTitle("Update"),
      requiresAuth: true,
    },
  },
  {
    path: "/models",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      props.order = route.query.order ?? null;
      return props;
    },
    component: () => import(/* webpackChunkName: 'models' */ "@/pages/models"),
    meta: {
      title: () => getTitle("Models"),
      requiresAuth: true,
    },
  },
  {
    path: "/profile/:id",
    props: (route) => {
      return {
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'model-profile' */ "@/pages/model-profile"),
    meta: {
      title: () => getTitle("Model Profile"),
      requiresAuth: true,
    },
  },
  {
    path: "/shoots",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      props.order = route.query.order ?? null;
      return props;
    },
    component: () => import(/* webpackChunkName: 'shoots' */ "@/pages/shoots"),
    meta: {
      title: () => getTitle("Shoots"),
      requiresAuth: true,
    },
  },
  {
    path: "/shoot/:id",
    props: (route) => {
      return {
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'shoot' */ "@/pages/shoot"),
    meta: {
      title: () => getTitle("Shoot"),
      requiresAuth: true,
    },
  },
  {
    path: "/shoot/:galleryId/images/:chapterId",
    props: (route) => {
      return {
        galleryId: parseInt(route.params.galleryId),
        chapterId: parseInt(route.params.chapterId),
      };
    },
    component: () => import(/* webpackChunkName: 'shoot-images' */ "@/pages/shoot-images"),
    meta: {
      title: () => getTitle("Shoot Images"),
      requiresAuth: true,
    },
  },
  {
    path: "/cameras",
    props: (route) => {
      const props = {};

      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;

      props.filters = route.query.filters ? route.query.filters.split(",").map((item) => parseInt(item)) : [];

      props.order = Number(route.query.hint) ? IMLIVE_SORT_FREE_CHAT : route.query.order ?? null;

      props.leaveCameraName = route.query.camera ?? null;

      props.exitCode = parseInt(route.query.exit_code);

      props.showHint = Boolean(Number(route.query.hint));

      return props;
    },
    component: () => import(/* webpackChunkName: 'cameras' */ "@/pages/cameras"),
    meta: {
      title: () => getTitle("Live Cams"),
      requiresAuth: true,
    },
  },
  {
    path: "/camera/:name",
    props: (route) => {
      return {
        name: route.params.name,
      };
    },
    component: () => import(/* webpackChunkName: 'camera-profile' */ "@/pages/camera-profile"),
    meta: {
      title: () => getTitle("Live Cams - Camera Profile"),
      requiresAuth: true,
    },
  },
  {
    path: "/camera/:name/photos",
    props: (route) => {
      return {
        name: route.params.name,
      };
    },
    component: () => import(/* webpackChunkName: 'camera-photo' */ "@/pages/camera-photos"),
    meta: {
      title: () => getTitle("Live Cams - Camera Photos"),
      requiresAuth: true,
    },
  },
  {
    path: "/playdates",
    component: () => import(/* webpackChunkName: 'playdates' */ "@/pages/playdates"),
    meta: {
      title: () => getTitle("Playdates"),
      requiresAuth: true,
    },
  },
  {
    path: "/private-playdate/:id",
    props: (route) => {
      return {
        type: "private",
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'playdate' */ "@/pages/playdate"),
    meta: {
      title: () => getTitle("Private Playdate"),
      requiresAuth: true,
      requiresPremium: true,
    },
  },
  {
    path: "/public-playdate/:id",
    props: (route) => {
      return {
        type: "public",
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'playdate' */ "@/pages/playdate"),
    meta: {
      title: () => getTitle("Public Playdate"),
      requiresAuth: true,
      requiresPremium: true,
    },
  },
  {
    path: "/netiquette",
    component: () => import(/* webpackChunkName: 'playdates-netiquette' */ "@/pages/playdate-netiquette"),
    meta: {
      title: () => getTitle("Ersties Playdate Netiquette for Viewers"),
      requiresAuth: true,
    },
  },
  {
    path: "/playdate-faq",
    component: () => import(/* webpackChunkName: 'playdate-faq' */ "@/pages/playdate-faq"),
    meta: {
      title: () => getTitle("Playdate FAQ"),
      requiresAuth: true,
    },
  },
  {
    path: "/favourite-models",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'favourite-models' */ "@/pages/favourite-models"),
    meta: {
      title: () => getTitle("Favourite models"),
      requiresAuth: true,
    },
  },
  {
    path: "/favourite-shoots",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'favourite-shoots' */ "@/pages/favourite-shoots"),
    meta: {
      title: () => getTitle("Favourite shoots"),
      requiresAuth: true,
    },
  },

  {
    path: "/favourite-videos",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'favourite-videos' */ "@/pages/favourite-videos"),
    meta: {
      title: () => getTitle("Favourite videos"),
      requiresAuth: true,
    },
  },

  {
    path: "/favourite-images",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'favourite-images' */ "@/pages/favourite-images"),
    meta: {
      title: () => getTitle("Favourite images"),
      requiresAuth: true,
    },
  },
  {
    path: "/favourite-cams",
    props: (route) => {
      const props = {};
      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;
      return props;
    },
    component: () => import(/* webpackChunkName: 'favourite-cams' */ "@/pages/favourite-cams"),
    meta: {
      title: () => getTitle("Favourite Camgirls"),
      requiresAuth: true,
    },
  },

  {
    path: "/user-profile",
    component: () => import(/* webpackChunkName: 'user-profile' */ "@/pages/user-profile"),
    meta: {
      title: () => getTitle("Member Area"),
      requiresAuth: true,
    },
  },
  {
    path: "/playdates/request-private-playdate/:id",
    props: (route) => {
      return {
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'private-playdate-request' */ "@/pages/private-playdate-request"),
    meta: {
      title: () => getTitle("Request a private playdate"),
      requiresAuth: true,
      requiresPremium: true,
    },
  },
  {
    path: "/playdates/extend-playdate/:id",
    props: (route) => {
      return {
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'private-playdate-extend' */ "@/pages/private-playdate-extend"),
    meta: {
      title: () => getTitle("Your Private Playdate has finished"),
      requiresAuth: true,
      requiresPremium: true,
    },
  },
  {
    path: "/user-profile/settings",
    component: () => import(/* webpackChunkName: 'user-profile-settings' */ "@/pages/user-profile-settings"),
    meta: {
      title: () => getTitle("Member Area"),
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    props: (route) => {
      const props = {};

      props.page = route.query.page ? Math.max(1, parseInt(route.query.page)) : 1;

      return props;
    },
    component: () => import(/* webpackChunkName: 'messages' */ "@/pages/messages"),
    meta: {
      title: () => getTitle("Messages"),
      requiresAuth: true,
    },
  },
  {
    path: "/messages/ersties-hq",
    props: (route) => {
      return {
        name: route.params.name,
      };
    },
    component: () => import(/* webpackChunkName: 'ersties-messages' */ "@/pages/ersties-messages"),
    meta: {
      title: () => getTitle("Messages"),
      requiresAuth: true,
    },
  },
  {
    path: "/messages/:name",
    props: (route) => {
      return {
        name: route.params.name,
      };
    },
    component: () => import(/* webpackChunkName: 'camera-offline-dialog' */ "@/pages/camera-offline-dialog"),
    meta: {
      title: () => getTitle("Messages"),
      requiresAuth: true,
    },
  },
  {
    path: "/playdate-messages/:id",
    props: (route) => {
      return {
        id: parseInt(route.params.id),
      };
    },
    component: () => import(/* webpackChunkName: 'playdate-offline-dialog' */ "@/pages/playdate-offline-dialog"),
    meta: {
      title: () => getTitle("Playdate offline Messages"),
      requiresAuth: true,
    },
  },
  {
    path: "/playdate-chat-replay",
    props: (route) => {
      return {
        token: route.query.token ?? "",
      };
    },
    component: () => import(/* webpackChunkName: 'playdate-chat-replay' */ "@/pages/playdate-chat-replay"),
    meta: {
      title: () => getTitle("Playdate chat replay"),
      layout: LAYOUT_BLANK,
    },
  },
  {
    path: "/free-chat/:name",
    props: (route) => {
      return {
        name: route.params.name,
        free: true,
      };
    },
    component: () => import(/* webpackChunkName: 'camera-chat' */ "@/pages/camera-chat"),
    meta: {
      title: () => getTitle("Live Cams - Camera Live Chat"),
      requiresAuth: true,
    },
  },
  {
    path: "/chat/:name",
    props: (route) => {
      return {
        name: route.params.name,
        free: false,
      };
    },
    component: () => import(/* webpackChunkName: 'camera-chat' */ "@/pages/camera-chat"),
    meta: {
      title: () => getTitle("Live Cams - Camera Live Chat"),
      requiresAuth: true,
    },
  },
  {
    path: "/paywall",
    component: () => import(/* webpackChunkName: 'paywall' */ "@/pages/paywall"),
    meta: {
      title: () => getTitle("Activate Your Membership"),
      requiresAuth: true,
    },
  },
  {
    path: "/community_guidelines",
    component: () => import(/* webpackChunkName: 'community-guidelines' */ "@/pages/community-guidelines"),
    meta: {
      title: () => getTitle("Community guidelines"),
      requiresAuth: true,
    },
  },
  {
    path: "/blog",
    props: (route) => {
      return {
        title: route.query.title,
        category: route.query.category,
      };
    },
    component: () => import(/* webpackChunkName: 'blog' */ "@/pages/blog"),
    meta: {
      layout: LAYOUT_V2_BLOG,
      title: () => getTitle("Ersties Blog"),
      v2headerTheme: V2_HEADER_THEME_NUDE,
    },
  },
  {
    path: "/lecker_blog",
    props: (route) => {
      return {
        title: route.query.title,
        category: route.query.category,
      };
    },
    component: () => import(/* webpackChunkName: 'blog' */ "@/pages/blog"),
    meta: {
      layout: LAYOUT_V2_BLOG,
      title: () => getTitle("Ersties Blog"),
      v2headerTheme: V2_HEADER_THEME_NUDE,
    },
  },
  {
    path: "/blog/:alias",
    props: (route) => {
      return {
        alias: route.params.alias,
      };
    },
    component: () => import(/* webpackChunkName: 'blog-post' */ "@/pages/blog-post"),
    meta: {
      layout: LAYOUT_V2_BLOG,
      title: () => getTitle("Ersties Blog"),
      v2headerTheme: V2_HEADER_THEME_PEACH,
    },
  },
  {
    path: "/lecker_blog/:alias",
    props: (route) => {
      return {
        alias: route.params.alias,
      };
    },
    component: () => import(/* webpackChunkName: 'blog-post' */ "@/pages/blog-post"),
    meta: {
      layout: LAYOUT_V2_BLOG,
      title: () => getTitle("Ersties Blog"),
      v2headerTheme: V2_HEADER_THEME_PEACH,
    },
  },
  {
    path: "/welcome/:id",
    name: "landing",
    component: () => import(/* webpackChunkName: 'landing' */ "@/pages/landing"),
    meta: {
      title: () => getTitle(),
    },
  },
  {
    path: "/contact-us",
    component: () => import(/* webpackChunkName: 'contact-us' */ "@/pages/contact-us"),
    meta: {
      title: () => getTitle("Contact Us"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/friends",
    component: () => import(/* webpackChunkName: 'friends' */ "@/pages/friends"),
    meta: {
      title: () => getTitle("Our Friends"),
      requiresAuth: true,
    },
  },
  {
    path: "/agb",
    component: () => import(/* webpackChunkName: 'agb-page' */ "@/pages/agb"),
    meta: {
      title: () => getTitle("2257 Notice / Legal"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/report",
    component: () => import(/* webpackChunkName: 'report' */ "@/pages/report"),
    meta: {
      title: () => getTitle("Content Removal"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/dmca",
    component: () => import(/* webpackChunkName: 'dmca' */ "@/pages/dmca"),
    meta: {
      title: () => getTitle("DMCA"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/prohibited_content",
    component: () => import(/* webpackChunkName: 'prohibited-content' */ "@/pages/prohibited-content"),
    meta: {
      title: () => getTitle("Prohibited Content"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/apply",
    component: () => import(/* webpackChunkName: 'apply' */ "@/pages/apply-models"),
    meta: {
      title: () => getTitle("In a nutshell:"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/sitemap",
    component: () => import(/* webpackChunkName: 'sitemap' */ "@/pages/sitemap"),
    meta: {
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/about_us",
    component: () => import(/* webpackChunkName: 'about_us' */ "@/pages/about-us"),
    meta: {
      title: () => getTitle("About Us"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/freetour",
    component: () => import(/* webpackChunkName: 'freetour' */ "@/pages/freetour"),
    meta: {
      title: () => getTitle("real amateur German students"),
      layout: LAYOUT_FREETOUR,
    },
  },
  {
    path: "/test_videos",
    component: () => import(/* webpackChunkName: 'test-videos' */ "@/pages/test-videos"),
    meta: {
      title: () => getTitle("Test videos"),
      layout: () => {
        const isLoggedIn = store.getters["user/isLoggedIn"];
        return isLoggedIn ? LAYOUT_MAIN : LAYOUT_V2;
      },
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "CustomPage",
    component: () => import(/* webpackChunkName: 'custom-page' */ "@/pages/custom-page"),
    meta: {
      title: () => getTitle(),
    },
  },
];
