import { loadLanguage } from "@/utils/storage";

export default () => {
  return {
    namespaced: true,
    state: {
      feedbackPopup: {
        show: false,
        type: null,
      },
      introducePopup: {
        show: false,
        type: null,
      },
    },
    getters: {
      showFeedbackPopup: (state) => state.feedbackPopup.show,
      feedbackPopupType: (state) => state.feedbackPopup.type,
      showIntroducePopup: (state) => state.introducePopup.show,
      introducePopupType: (state) => state.introducePopup.type,
      hasFrench(state, getters, rootState, rootGetters) {
        const visit = rootGetters["visit/getVisit"];
        const isVisitFeatureActive = visit?.features?.french && visit?.features?.french?.is_active;
        const profile = rootGetters["user/getProfile"];
        const isProfileFeatureActive = profile?.features?.french && profile?.features?.french?.is_active;
        return isVisitFeatureActive || isProfileFeatureActive || loadLanguage() === "fr";
      },
      hasVAT(state, getters, rootState, rootGetters) {
        const profile = rootGetters["user/getProfile"];
        const feature = profile?.features?.vat;
        return feature && feature?.is_active;
      },
      hasCCDiscount(state, getters, rootState, rootGetters) {
        const profile = rootGetters["user/getProfile"];
        const feature = profile?.features?.cc_discount;
        return feature && feature?.is_active;
      },
    },
    mutations: {
      SET_FEEDBACK_POPUP(state, { show, type }) {
        state.feedbackPopup.show = show;
        state.feedbackPopup.type = type;
      },
      SET_INTRODUCE_POPUP(state, { show, type }) {
        state.introducePopup.show = show;
        state.introducePopup.type = type;
      },
    },
    actions: {
      setFeedbackPopup({ commit }, { show, type }) {
        commit("SET_FEEDBACK_POPUP", { show, type });
      },
      setIntroducePopup({ commit }, { show, type }) {
        commit("SET_INTRODUCE_POPUP", { show, type });
      },
    },
  };
};
